<template>
  <v-container>
    <!-- BOC:[error] -->
    <AError
      v-if="api.isError"
      :api="api"
      :callbackReset="() => (api.isError = false)"
    ></AError>
    <v-card
      v-if="!api.isError"
      :loading="api.isLoading"
      elevation="0"
      class="mx-auto"
      max-width="800"
      outlined
      color="orange lighten-3"
    >
      <v-container v-if="data.latest && data.latest.length != 0">
        <div class="text-h4 pb-2 pl-2 text-center mb-4">Latest Events</div>
        <v-row dense>
          <v-col
            v-for="event in data.latest"
            :key="event.code"
            class="pb-3"
            cols="12"
            sm="6"
            md="4"
          >
            <v-card class="mx-auto" max-width="344">
              <v-img :src="event.imageUrl">
                <v-chip class="ma-2" dark color="black">
                  {{ event.subjectName }}
                </v-chip></v-img
              >
              <v-card-title> {{ event.name }} </v-card-title>
              <v-card-subtitle>
                <EventDate :data="event"></EventDate>
              </v-card-subtitle>
              <v-card-actions class="my-0 py-0">
                <v-spacer></v-spacer>
                <v-btn
                  :to="{
                    name: 'PageMainEventScoreboard',
                    params: { eventCode: event.code },
                  }"
                  color="yellow"
                  class="mb-2"
                >
                  {{ $t("action.view") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-container v-if="data.ongoing && data.ongoing.length != 0">
        <div class="text-h4 pb-2 pl-2 text-center mb-4">Ongoing Events</div>
        <v-row dense>
          <v-col
            v-for="event in data.ongoing"
            :key="event.code"
            class="pb-3"
            cols="12"
            sm="6"
            md="4"
          >
            <v-card class="mx-auto" max-width="344">
              <v-img :src="event.imageUrl">
                <v-chip class="ma-2" dark color="black">
                  {{ event.subjectName }}
                </v-chip></v-img
              >
              <v-card-title> {{ event.name }} </v-card-title>
              <v-card-subtitle>
                <EventDate :data="event"></EventDate>
              </v-card-subtitle>
              <v-card-actions class="my-0 py-0">
                <v-spacer></v-spacer>
                <v-btn
                  :to="{
                    name: 'PageMainEventScoreboard',
                    params: { eventCode: event.code },
                  }"
                  color="yellow"
                  class="mb-2"
                >
                  {{ $t("action.view") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-container v-if="data.comingSoon && data.comingSoon.length != 0">
        <div class="text-h4 pb-2 pl-2 text-center mb-4">Upcoming Events</div>
        <v-row dense>
          <v-col
            v-for="event in data.comingSoon"
            :key="event.code"
            class="pb-3"
            cols="12"
            sm="6"
            md="4"
          >
            <v-card class="mx-auto" max-width="344">
              <v-img :src="event.imageUrl">
                <v-chip class="ma-2" dark color="black">
                  {{ event.subjectName }}
                </v-chip></v-img
              >
              <v-card-title> {{ event.name }} </v-card-title>
              <v-card-subtitle>
                <EventDate :data="event"></EventDate>
              </v-card-subtitle>
              <v-card-actions class="my-0 py-0">
                <v-spacer></v-spacer>
                <v-btn
                  :to="{
                    name: 'PageMainEventScoreboard',
                    params: { eventCode: event.code },
                  }"
                  color="yellow"
                  class="mb-2"
                >
                  {{ $t("action.view") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-container v-if="data.upcoming && data.upcoming.length != 0">
        <div class="text-h4 pb-2 pl-2 text-center mb-4">Future Events</div>
        <v-row dense>
          <v-col
            v-for="event in data.upcoming"
            :key="event.code"
            class="pb-3"
            cols="12"
            sm="6"
            md="4"
          >
            <v-card class="mx-auto" max-width="344">
              <v-img :src="event.imageUrl">
                <v-chip class="ma-2" dark color="black">
                  {{ event.subjectName }}
                </v-chip></v-img
              >
              <v-card-title> {{ event.name }} </v-card-title>
              <v-card-subtitle>
                <EventDate :data="event"></EventDate>
              </v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-container v-if="data.past && data.past.length != 0">
        <div class="text-h4 pb-2 pl-2 text-center mb-4">Past Events</div>
        <v-row dense>
          <v-col
            v-for="event in data.past"
            :key="event.code"
            class="pb-3"
            cols="12"
            sm="6"
            md="4"
          >
            <v-card class="mx-auto" max-width="344" style="opacity: 0.5; background-color: rgba(0,0,0,0.1);">
              <v-img :src="event.imageUrl">
                <v-chip class="ma-2" dark color="black">
                  {{ event.subjectName }}
                </v-chip></v-img
              >
              <v-card-title> {{ event.name }} </v-card-title>
              <v-card-subtitle>
                <EventDate :data="event"></EventDate>
              </v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
// import EventStatus from "@/components/Event/EventStatus";
import EventDate from "@/components/Event/EventDate";
// import EventGamePoster from "@/components/Event/EventGamePoster";
import { mapState } from "vuex";
export default {
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  components: {
    // EventStatus,
    EventDate,
    // EventGamePoster,
  },
  data: () => ({
    buttonText: "",
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    data: [],
    seriesCurrent: null,
    seriesPrevious: null,
  }),
  created() {
    //BOC:[api]
    this.api.method = "get";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.data = resp.data;
      // this.seriesCurrent = this.$_.maxBy(data,(o)=>{
      //   return o.Series.id;
      // }).Series
      // this.data = this.$_.filter(data, (o) => {
      //   return o.festivalId == null;
      // });
    };
    //EOC
  },

  methods: {
    fetch() {
      this.api.url =
        this.$api.servers.event +
        "/api/v2/" +
        this.$_getLocale() +
        "/main/event";
      this.$api.fetch(this.api);
    },
  },
  mounted() {
    this.fetch();
    this.buttonText = this.$t("action.view");
  },
};
</script>

<style>
.view-event::before {
  background-color: transparent !important;
}
.v-data-table__wrapper::-webkit-scrollbar {
  display: none;
}
.v-data-table__wrapper {
  -ms-overflow-style: none;
}
</style>