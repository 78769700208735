var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.api.isError)?_c('AError',{attrs:{"api":_vm.api,"callbackReset":function () { return (_vm.api.isError = false); }}}):_vm._e(),(!_vm.api.isError)?_c('v-card',{staticClass:"mx-auto",attrs:{"loading":_vm.api.isLoading,"elevation":"0","max-width":"800","outlined":"","color":"orange lighten-3"}},[(_vm.data.latest && _vm.data.latest.length != 0)?_c('v-container',[_c('div',{staticClass:"text-h4 pb-2 pl-2 text-center mb-4"},[_vm._v("Latest Events")]),_c('v-row',{attrs:{"dense":""}},_vm._l((_vm.data.latest),function(event){return _c('v-col',{key:event.code,staticClass:"pb-3",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"344"}},[_c('v-img',{attrs:{"src":event.imageUrl}},[_c('v-chip',{staticClass:"ma-2",attrs:{"dark":"","color":"black"}},[_vm._v(" "+_vm._s(event.subjectName)+" ")])],1),_c('v-card-title',[_vm._v(" "+_vm._s(event.name)+" ")]),_c('v-card-subtitle',[_c('EventDate',{attrs:{"data":event}})],1),_c('v-card-actions',{staticClass:"my-0 py-0"},[_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"to":{
                  name: 'PageMainEventScoreboard',
                  params: { eventCode: event.code },
                },"color":"yellow"}},[_vm._v(" "+_vm._s(_vm.$t("action.view"))+" ")])],1)],1)],1)}),1)],1):_vm._e(),(_vm.data.ongoing && _vm.data.ongoing.length != 0)?_c('v-container',[_c('div',{staticClass:"text-h4 pb-2 pl-2 text-center mb-4"},[_vm._v("Ongoing Events")]),_c('v-row',{attrs:{"dense":""}},_vm._l((_vm.data.ongoing),function(event){return _c('v-col',{key:event.code,staticClass:"pb-3",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"344"}},[_c('v-img',{attrs:{"src":event.imageUrl}},[_c('v-chip',{staticClass:"ma-2",attrs:{"dark":"","color":"black"}},[_vm._v(" "+_vm._s(event.subjectName)+" ")])],1),_c('v-card-title',[_vm._v(" "+_vm._s(event.name)+" ")]),_c('v-card-subtitle',[_c('EventDate',{attrs:{"data":event}})],1),_c('v-card-actions',{staticClass:"my-0 py-0"},[_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"to":{
                  name: 'PageMainEventScoreboard',
                  params: { eventCode: event.code },
                },"color":"yellow"}},[_vm._v(" "+_vm._s(_vm.$t("action.view"))+" ")])],1)],1)],1)}),1)],1):_vm._e(),(_vm.data.comingSoon && _vm.data.comingSoon.length != 0)?_c('v-container',[_c('div',{staticClass:"text-h4 pb-2 pl-2 text-center mb-4"},[_vm._v("Upcoming Events")]),_c('v-row',{attrs:{"dense":""}},_vm._l((_vm.data.comingSoon),function(event){return _c('v-col',{key:event.code,staticClass:"pb-3",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"344"}},[_c('v-img',{attrs:{"src":event.imageUrl}},[_c('v-chip',{staticClass:"ma-2",attrs:{"dark":"","color":"black"}},[_vm._v(" "+_vm._s(event.subjectName)+" ")])],1),_c('v-card-title',[_vm._v(" "+_vm._s(event.name)+" ")]),_c('v-card-subtitle',[_c('EventDate',{attrs:{"data":event}})],1),_c('v-card-actions',{staticClass:"my-0 py-0"},[_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"to":{
                  name: 'PageMainEventScoreboard',
                  params: { eventCode: event.code },
                },"color":"yellow"}},[_vm._v(" "+_vm._s(_vm.$t("action.view"))+" ")])],1)],1)],1)}),1)],1):_vm._e(),(_vm.data.upcoming && _vm.data.upcoming.length != 0)?_c('v-container',[_c('div',{staticClass:"text-h4 pb-2 pl-2 text-center mb-4"},[_vm._v("Future Events")]),_c('v-row',{attrs:{"dense":""}},_vm._l((_vm.data.upcoming),function(event){return _c('v-col',{key:event.code,staticClass:"pb-3",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"344"}},[_c('v-img',{attrs:{"src":event.imageUrl}},[_c('v-chip',{staticClass:"ma-2",attrs:{"dark":"","color":"black"}},[_vm._v(" "+_vm._s(event.subjectName)+" ")])],1),_c('v-card-title',[_vm._v(" "+_vm._s(event.name)+" ")]),_c('v-card-subtitle',[_c('EventDate',{attrs:{"data":event}})],1)],1)],1)}),1)],1):_vm._e(),(_vm.data.past && _vm.data.past.length != 0)?_c('v-container',[_c('div',{staticClass:"text-h4 pb-2 pl-2 text-center mb-4"},[_vm._v("Past Events")]),_c('v-row',{attrs:{"dense":""}},_vm._l((_vm.data.past),function(event){return _c('v-col',{key:event.code,staticClass:"pb-3",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-card',{staticClass:"mx-auto",staticStyle:{"opacity":"0.5","background-color":"rgba(0,0,0,0.1)"},attrs:{"max-width":"344"}},[_c('v-img',{attrs:{"src":event.imageUrl}},[_c('v-chip',{staticClass:"ma-2",attrs:{"dark":"","color":"black"}},[_vm._v(" "+_vm._s(event.subjectName)+" ")])],1),_c('v-card-title',[_vm._v(" "+_vm._s(event.name)+" ")]),_c('v-card-subtitle',[_c('EventDate',{attrs:{"data":event}})],1)],1)],1)}),1)],1):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }